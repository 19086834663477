<template>
  <div
    id="app"
    class="app"
  >
    <header class="app__header">
      <main-menu />
    </header>

    <main class="app__content">
      <div>
        <nuxt-page />
      </div>
    </main>

    <Footer />
  </div>
</template>

<script lang="ts">
import MainMenu from '@/core/components/menu/MainMenu.vue'
import Footer from '@/core/components/footer/Footer.vue'

export default defineNuxtComponent({
  components: {
    MainMenu,
    Footer,
  },
})
</script>

<style lang="scss" scoped>
.app {
  @screen lg {
    @apply bg-spl-gray-1;
  }

  &__header {
    @apply pt-1;
    @apply px-1;
    @apply bg-white;

    @screen lg {
      @apply p-0;
      @apply bg-transparent;
      @apply transition-all duration-500;
    }
  }
}

.content {
  @apply mx-auto;
  @screen lg {
    width: 1100px;
  }

  @screen sm {
    @apply mx-auto;
  }
}
</style>
